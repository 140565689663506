.w4-newsletterform {
    background-color: $gray-555;

    iframe {
        height: 365px;
        padding-left: 10px;
        padding-right: 10px;
    }

    h4 {
        color: $white;
        font-size: 2.063rem;
        padding-top: 60px;
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }

    .newsletter-svg-container {
        position: relative;
    }

    svg {
        position: absolute;
        height: 50px;
        width: 157px;
        left: 20px;
        top: -15px;
    }

    @media(min-width: 576px) {
        iframe {
            height: 316px;
        }

        svg {
            height: 100px;
            width: 314px;
            top: -45px;
        }
    }
    @media(min-width: 596px) {
        iframe {
            height: 202px;
        }
    }
    @media(min-width: 768px) {
        iframe {
            height: 196px;
        }
    }
    @media(min-width: 992px) {
        iframe {
            height: 100px;
        }
    }
    @media(min-width: 1024px) {
        svg {
            top: -20px;
        }

        .newsletter-svg-container {
            max-width: 992px;
            margin: 0 auto;
        }
    }

    @media(min-width: 1200px) {
        svg {
            left: -90px;
        }
    }
}

