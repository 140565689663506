//
// Base styles
//

.btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;

    @include hover {
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 1px dotted;
        outline-offset: 2px;
    }

    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        pointer-events: none;
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-default {
    position: relative;
    color: $btn-default-color;

    font-size: $btn-sm-font-size;
    text-transform: uppercase;
    padding: 0.625rem $btn-sm-padding-x $btn-sm-padding-y $btn-sm-padding-x;

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($btn-default-color, 0.2);

        &::before,
        &::after {
            width: 100%;
            height: 100%;
            border-color: $btn-default-color;
        }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background: darken($light, 6%);
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        background: transparent;
    }

    &::before  {
        bottom: 0;
        right: 0;
        width: 100%;
        border-bottom: 1px solid $btn-default-color;
        border-right: 1px solid $btn-default-color;
        transition-property: height;
        transition-delay: 0.4s;
        transition-duration: 0.2s;
    }

    &::after  {
        top: 0;
        right: 0;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        transition-property: height, width, border-color;
        transition-delay: 0s, 0.2s, 0.4s;
        transition-duration: 0.2s, 0.2s, 0s;
    }

    &:hover {
        color: $btn-default-color;

        &::before  {
            height: 100%;
            transition-delay: 0s;
        }

        &::after {
            width: 100%;
            height: 100%;
            border-top-color: $btn-default-color;
            border-left-color: $btn-default-color;
            transition-property: border-color, width, height;
            transition-delay: 0.2s, 0.2s, 0.4s;
            transition-duration: 0s, 0.2s, 0.2s;
        }
    }
}

.btn-secondary {
    color: $white;
    background-color: $primary;
    border-color: $primary;

    font-size: $btn-font-size;
    text-transform: uppercase;
    padding: 0.625rem $btn-sm-padding-x $btn-sm-padding-y $btn-sm-padding-x;
    transition: $btn-transition, transform 0.5s;

    @include hover {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        transform: scale(1.05);
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.2);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: $white;
        background: darken($primary, 6%);
    }
}


.btn-white {
    color: $body-color;
    background-color: rgba($dark, 0);
    border-color: $body-color;

    font-size: $btn-font-size;
    text-transform: uppercase;
    padding: $btn-padding-y $btn-padding-x;
    transition: $btn-transition;

    @include hover {
        color: $body-color;
        background-color: $gray-300;
        border-color: $gray-300;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($white, 0.2);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background: rgba($dark, 0.5);
    }
}

.btn-red {
    color: $white;
    background-color: $primary;
    border-color: $primary;

    font-size: $btn-font-size;
    text-transform: uppercase;
    padding: $btn-padding-y $btn-padding-x;
    transition: $btn-transition;

    @include hover {
        color: $body-color;
        background-color: $gray-300;
        border-color: $gray-300;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($primary, 0.2);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: $white;
        background: darken($primary, 6%);
    }

    @include media-breakpoint-up(lg) {
        font-size: $btn-font-size-lg;
        padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    color: $link-color;
    text-decoration: $link-decoration;
    border: 0;

    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    // No need for an active state here
}


//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}
