// Navbar




.navbar {
    font-size: $font-size-sm;
    border-bottom: 2px solid transparent;
    @include transition(background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, transform 0.25s ease-in-out);

    .nav-item {
        .fa-chevron-down {
            font-size: 0.625rem;
        }
    }
}

.navbar-expand-lg {
    .navbar-nav {
        div[aria-labelledby="nav-item-193"] {
            @include media-breakpoint-up(lg) {
                left: -90px;
            }

            @media (min-width: 1100px) {
                left: -60px;
            }

            @include media-breakpoint-up(xl) {
                left: -20px;
            }

            @media (min-width: 1520px) {
                left: 50%;
            }
        }

        .dropdown-menu {
            li {
                &::before {
                    content: '' !important;
                }
            }
        }
    }
}

.w4-nav-shadow {
    position: absolute;
    width: 100%;
    height: 18px;
    bottom: 0;
    background: $navbar-shadow-inner;
}

.w4-navbar-wrapper {
    background-color: $navbar-light-background-color;
    height: 120px;
}

.w4-mobile-langswitcher {
    display: none;
}

.navbar-light {
    background-color: none;
}

.navbar-toggler {
    &:focus {
        outline: none;
    }
}

.toggler-icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 12px;

    span{
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        opacity: 1;
        left: 0;
        background-color: $primary;
        border-radius: $border-radius;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0;
    }

    span:nth-child(2),
    span:nth-child(3) {
        top: 5px;
    }

    span:nth-child(4) {
        top: 10px;
    }
}

.menu-open {
    .toggler-icon {
        span {
            background-color: $white;
        }

        span:nth-child(1),
        span:nth-child(4) {
            top: 5px;
            width: 0;
            left: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
        }
    }
}

.navbar-expand-lg .navbar-collapse,
.dropdown-menu {
    background: $dark url('../../Images/hatched-dark.jpg') repeat;
}

@include media-breakpoint-down(md) {
    .navbar-light {
        .navbar-nav {
            a.nav-link {
                color: $white;
                text-align: center;
                font-family: $font-family-dmserifdisplay;
                font-size: 1.875rem;
                justify-content: center;
                padding: 0;
                line-height: 2;
            }
        }
    }
}

.menu-open {
    .w4-navbar-wrapper,
    .navbar-expand-lg .navbar-collapse,
    .w4-mobile-navigation-bar,
    .dropdown-menu {
        background: $dark url('../../Images/hatched-dark.jpg') repeat;
    }

    svg {
        fill: $white;
    }

    .navbar-logo-color {
        display: none;
    }
    .navbar-logo-white {
        display: block;
    }

    .w4-navbar-wrapper {
        .w4-nav-shadow {
            background: none;
        }
    }

    .w4-mobile-langswitcher {
        display: block;
        margin-right: 10px;
    }

    .w4-mobile-navigation-bar {
        justify-content: flex-end;
        min-height: 50px;

        .btn-secondary {
            display: none;
        }

        .w4-burger {
            padding-right: 0;
        }
    }

    .navbar-light {
        .navbar-nav {
            padding-bottom: 1.625rem;
        }
    }

    .dropdown-close-btn {
        color: $white;
        text-transform: uppercase;
        font-family: $font-family-futurapt;
        font-size: 0.625rem;

        &::before {
            content: ' ';
            background: url('../../Icons/Theme/arrow2white.svg');
            height: 8px;
            width: 8px;
            display: inline-block;
            transform: rotate(90deg);
        }

        &:active,
        &:hover {
            background: transparent;
        }
    }

    .dropdown-menu {
        text-align: center;
        border: 0;

        ul {
            padding-left: 0;

            li a {
                color: $white;
                text-transform: uppercase;
                font-family: $font-family-futurapt;
                font-size: 1.063rem;
                line-height: 2.188rem;

                &:hover,
                &:focus {
                    background: none;
                }
            }
        }

        .dropdown-title {
            padding-left: 0 !important;


            a {
                color: $white;
                font-family: $font-family-dmserifdisplay;
                font-size: 1.875rem;
                line-height: 2;
            }
        }
    }
}

.w4-mobile-navigation-bar {
    .w4-booking-link {
        max-width: 130px;

        @media (min-width: 350px) {
            max-width: none;
        }
    }

    .w4-burger {
        display: inline-block;
        margin-left: 10px;
    }
}

@include media-breakpoint-down(md) {
    .navbar-brand {
        max-height: 100px;

        img {
            max-height: 100px;
        }
    }
}

@include media-breakpoint-up(lg) {

    .w4-navbar-wrapper {
        background-color: $navbar-light-background-color;
        height: 180px;
        transition: height 0.2s;
    }

    .navbar-logo {
        height: 145px;
        transition: height 0.2s;
        margin-top: -7px
    }

    .scrolled {
        .w4-nav-shadow {
            height: 15px;
        }

        .w4-navbar-wrapper {
            height: 110px;
            transition: height 0.2s;

            .navbar-brand {
                padding-top: 0;
                padding-bottom: 10px;
                transition: all 0.2s;

                .navbar-logo {
                    height: 110px;
                    margin-top: -18px;
                    transition: height 0.2s;
                }
            }
        }

        .nav-link {
            padding-bottom: 1.25rem;
            transition: all 0.3s;
        }
    }

    .navbar-expand-lg .navbar-collapse {
        background: $navbar-light-background-color;
    }

    .navbar-right {
        display: flex;
        align-items: center;

        .navbar-nav {
            margin-right: 0.5rem;
        }
    }

    .navbar-expand-lg {
        .navbar-nav {
            margin-top: 2px;
            align-items: flex-end;

            .nav-link {
                line-height: 1.2;
                font-size: 1.063rem;

                &::after {
                    content: ' ';
                    display: block;
                    width: 100%;
                    border-bottom: 2px solid transparent;
                }
            }



            .nav-item {
                &:hover {
                    .nav-link {
                        &::after {
                            border-bottom: 2px solid $red;
                        }
                    }
                }
            }

            .dropdown-menu {
                margin-top: -15px;
                left: 50%;
                transform: translate(-50%, 0);
                padding: 30px;
                background: $white;
                border: 0;
                box-shadow: $dropdown-shadow;
                text-transform: uppercase;
                font-weight: $font-weight-bold;

                &.show {
                    animation: dropdown-fade 250ms ease-out forwards;
                    display: flex;
                    align-items: center;

                    .dropdown-title a {
                        padding: 0.313rem 1.5rem !important;
                        font-size: 1.25rem;
                        color: $red;
                    }

                    .dropdown-menu-item {
                        a {
                            font-size: 1.063rem;
                            font-weight: $font-weight-bold;
                            padding-top: 0.188rem;
                            padding-bottom: 0.188rem;
                        }
                    }

                    .navigation-teaser {
                        &::before {
                            content: ' ';
                            display: block;
                            position: absolute;
                            z-index: -1;
                            width: 230px;
                            height: 197px;
                            background: $light url('../../Images/hatched.jpg') repeat;
                        }

                        picture {
                            display: block;
                            margin-top: 15px;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }

    .nav-item {
        .fa-chevron-down {
            position: relative;
            top: -1px;
            margin-left: 0.5rem;
        }
    }
}

@keyframes dropdown-fade {
    from {
        opacity: 0;
        transform: translate(-50%, 1rem);
    }
    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }

}

@media (min-width: 1316px) {
    .navbar-brand {
        margin-right: 2rem;
    }
}

/*IE 11 Browserhack to fix Submenu background issue*/
@media all and (-ms-high-contrast:none) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        min-width: 600px;
    }
}