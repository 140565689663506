@charset "UTF-8";
/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300.woff2") format("woff2"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300.woff") format("woff"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300.ttf") format("truetype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300.svg#Raleway") format("svg");
  /* Legacy iOS */
}

/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500.eot?#iefix") format("embedded-opentype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500.woff2") format("woff2"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500.woff") format("woff"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500.ttf") format("truetype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500.svg#Raleway") format("svg");
  /* Legacy iOS */
}

/* raleway-300italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300italic.woff2") format("woff2"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300italic.woff") format("woff"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300italic.ttf") format("truetype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-300italic.svg#Raleway") format("svg");
  /* Legacy iOS */
}

/* raleway-500italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500italic.eot?#iefix") format("embedded-opentype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500italic.woff2") format("woff2"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500italic.woff") format("woff"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500italic.ttf") format("truetype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Raleway/raleway-v18-latin-500italic.svg#Raleway") format("svg");
  /* Legacy iOS */
}

/* dm-serif-display-regular - latin */
@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-regular.eot?#iefix") format("embedded-opentype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-regular.woff2") format("woff2"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-regular.woff") format("woff"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-regular.ttf") format("truetype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-regular.svg#DMSerifDisplay") format("svg");
  /* Legacy iOS */
}

/* dm-serif-display-italic - latin */
@font-face {
  font-family: 'DM Serif Display';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-italic.eot?#iefix") format("embedded-opentype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-italic.woff2") format("woff2"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-italic.woff") format("woff"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-italic.ttf") format("truetype"), url("/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/Dmserifdisplay/dm-serif-display-v4-latin-italic.svg#DMSerifDisplay") format("svg");
  /* Legacy iOS */
}

@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "futura-pt";
  src: url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Icomoon';
  src: url("../../Fonts/Icomoon/icomoon.eot?tmhb7e");
  src: url("../../Fonts/Icomoon/icomoon.eot?tmhb7e#iefix") format("embedded-opentype"), url("../../Fonts/Icomoon/icomoon.ttf?tmhb7e") format("truetype"), url("../../Fonts/Icomoon/icomoon.woff?tmhb7e") format("woff"), url("../../Fonts/Icomoon/icomoon.svg?tmhb7e#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="if-"], [class*=" if-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.if-sun:before,
.if-01d:before {
  content: "\e603";
}

.if-moon:before,
.if-01n:before {
  content: "\e604";
}

.if-cloudy:before,
.if-02d:before {
  content: "\e608";
}

.if-cloud:before,
.if-02n:before {
  content: "\e609";
}

.if-lines:before,
.if-50d:before,
.if-50n:before {
  content: "\e60d";
}

.if-cloud2:before,
.if-03d:before,
.if-03n:before {
  content: "\e60e";
}

.if-rainy:before,
.if-10d:before,
.if-10n:before {
  content: "\e611";
}

.if-rainy2:before,
.if-09d:before,
.if-09n:before {
  content: "\e612";
}

.if-snowy3:before,
.if-13d:before,
.if-13n:before {
  content: "\e617";
}

.if-cloudy2:before,
.if-04d:before,
.if-04n:before {
  content: "\e619";
}

.if-lightning3:before,
.if-11d:before,
.if-11n:before {
  content: "\e61b";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #7c7c7c;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2b2b2b;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #7c7c7c;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #7c7c7c;
}

.table tbody + tbody {
  border-top: 2px solid #7c7c7c;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #7c7c7c;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #7c7c7c;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #2b2b2b;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #7c7c7c;
  background-color: #f1f1f1;
  border-color: #7c7c7c;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1419.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2b2b2b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1420px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f1f1f1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #2b2b2b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:first-child {
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}

.dropdown-item:last-child {
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e1e1e;
  text-decoration: none;
  background-color: #f6f6f6;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #870337;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #2b2b2b;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1rem 1.1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e9e9e9;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f1f1f1 #f1f1f1 #e9e9e9;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #7c7c7c;
  background-color: #fff;
  border-color: #e9e9e9 #e9e9e9 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #870337;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f1f1f1;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9e9e9 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #7c7c7c;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #7c7c7c;
  }
}

.navbar {
  font-size: 0.875rem;
  border-bottom: 2px solid transparent;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, transform 0.25s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar {
    transition: none;
  }
}

.navbar .nav-item .fa-chevron-down {
  font-size: 0.625rem;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav div[aria-labelledby="nav-item-193"] {
    left: -90px;
  }
}

@media (min-width: 1100px) {
  .navbar-expand-lg .navbar-nav div[aria-labelledby="nav-item-193"] {
    left: -60px;
  }
}

@media (min-width: 1420px) {
  .navbar-expand-lg .navbar-nav div[aria-labelledby="nav-item-193"] {
    left: -20px;
  }
}

@media (min-width: 1520px) {
  .navbar-expand-lg .navbar-nav div[aria-labelledby="nav-item-193"] {
    left: 50%;
  }
}

.navbar-expand-lg .navbar-nav .dropdown-menu li::before {
  content: '' !important;
}

.w4-nav-shadow {
  position: absolute;
  width: 100%;
  height: 18px;
  bottom: 0;
  background: linear-gradient(0deg, #e9e9e9 0%, #f8f8f8 100%);
}

.w4-navbar-wrapper {
  background-color: #f8f8f8;
  height: 120px;
}

.w4-mobile-langswitcher {
  display: none;
}

.navbar-light {
  background-color: none;
}

.navbar-toggler:focus {
  outline: none;
}

.toggler-icon {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 12px;
}

.toggler-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 1;
  left: 0;
  background-color: #870337;
  border-radius: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.toggler-icon span:nth-child(1) {
  top: 0;
}

.toggler-icon span:nth-child(2),
.toggler-icon span:nth-child(3) {
  top: 5px;
}

.toggler-icon span:nth-child(4) {
  top: 10px;
}

.menu-open .toggler-icon span {
  background-color: #fff;
}

.menu-open .toggler-icon span:nth-child(1),
.menu-open .toggler-icon span:nth-child(4) {
  top: 5px;
  width: 0;
  left: 50%;
}

.menu-open .toggler-icon span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-open .toggler-icon span:nth-child(3) {
  transform: rotate(-45deg);
}

.navbar-expand-lg .navbar-collapse,
.dropdown-menu {
  background: #2b2b2b url("../../Images/hatched-dark.jpg") repeat;
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav a.nav-link {
    color: #fff;
    text-align: center;
    font-family: "DM Serif Display", sans-serif;
    font-size: 1.875rem;
    justify-content: center;
    padding: 0;
    line-height: 2;
  }
}

.menu-open .w4-navbar-wrapper,
.menu-open .navbar-expand-lg .navbar-collapse,
.menu-open .w4-mobile-navigation-bar,
.menu-open .dropdown-menu {
  background: #2b2b2b url("../../Images/hatched-dark.jpg") repeat;
}

.menu-open svg {
  fill: #fff;
}

.menu-open .navbar-logo-color {
  display: none;
}

.menu-open .navbar-logo-white {
  display: block;
}

.menu-open .w4-navbar-wrapper .w4-nav-shadow {
  background: none;
}

.menu-open .w4-mobile-langswitcher {
  display: block;
  margin-right: 10px;
}

.menu-open .w4-mobile-navigation-bar {
  justify-content: flex-end;
  min-height: 50px;
}

.menu-open .w4-mobile-navigation-bar .btn-secondary {
  display: none;
}

.menu-open .w4-mobile-navigation-bar .w4-burger {
  padding-right: 0;
}

.menu-open .navbar-light .navbar-nav {
  padding-bottom: 1.625rem;
}

.menu-open .dropdown-close-btn {
  color: #fff;
  text-transform: uppercase;
  font-family: "futura-pt", sans-serif;
  font-size: 0.625rem;
}

.menu-open .dropdown-close-btn::before {
  content: ' ';
  background: url("../../Icons/Theme/arrow2white.svg");
  height: 8px;
  width: 8px;
  display: inline-block;
  transform: rotate(90deg);
}

.menu-open .dropdown-close-btn:active, .menu-open .dropdown-close-btn:hover {
  background: transparent;
}

.menu-open .dropdown-menu {
  text-align: center;
  border: 0;
}

.menu-open .dropdown-menu ul {
  padding-left: 0;
}

.menu-open .dropdown-menu ul li a {
  color: #fff;
  text-transform: uppercase;
  font-family: "futura-pt", sans-serif;
  font-size: 1.063rem;
  line-height: 2.188rem;
}

.menu-open .dropdown-menu ul li a:hover, .menu-open .dropdown-menu ul li a:focus {
  background: none;
}

.menu-open .dropdown-menu .dropdown-title {
  padding-left: 0 !important;
}

.menu-open .dropdown-menu .dropdown-title a {
  color: #fff;
  font-family: "DM Serif Display", sans-serif;
  font-size: 1.875rem;
  line-height: 2;
}

.w4-mobile-navigation-bar .w4-booking-link {
  max-width: 130px;
}

@media (min-width: 350px) {
  .w4-mobile-navigation-bar .w4-booking-link {
    max-width: none;
  }
}

.w4-mobile-navigation-bar .w4-burger {
  display: inline-block;
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .navbar-brand {
    max-height: 100px;
  }
  .navbar-brand img {
    max-height: 100px;
  }
}

@media (min-width: 992px) {
  .w4-navbar-wrapper {
    background-color: #f8f8f8;
    height: 180px;
    transition: height 0.2s;
  }
  .navbar-logo {
    height: 145px;
    transition: height 0.2s;
    margin-top: -7px;
  }
  .scrolled .w4-nav-shadow {
    height: 15px;
  }
  .scrolled .w4-navbar-wrapper {
    height: 110px;
    transition: height 0.2s;
  }
  .scrolled .w4-navbar-wrapper .navbar-brand {
    padding-top: 0;
    padding-bottom: 10px;
    transition: all 0.2s;
  }
  .scrolled .w4-navbar-wrapper .navbar-brand .navbar-logo {
    height: 110px;
    margin-top: -18px;
    transition: height 0.2s;
  }
  .scrolled .nav-link {
    padding-bottom: 1.25rem;
    transition: all 0.3s;
  }
  .navbar-expand-lg .navbar-collapse {
    background: #f8f8f8;
  }
  .navbar-right {
    display: flex;
    align-items: center;
  }
  .navbar-right .navbar-nav {
    margin-right: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav {
    margin-top: 2px;
    align-items: flex-end;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    line-height: 1.2;
    font-size: 1.063rem;
  }
  .navbar-expand-lg .navbar-nav .nav-link::after {
    content: ' ';
    display: block;
    width: 100%;
    border-bottom: 2px solid transparent;
  }
  .navbar-expand-lg .navbar-nav .nav-item:hover .nav-link::after {
    border-bottom: 2px solid #870337;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: -15px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 30px;
    background: #fff;
    border: 0;
    box-shadow: -2px 1px 18px 1px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    font-weight: 500;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show {
    animation: dropdown-fade 250ms ease-out forwards;
    display: flex;
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-title a {
    padding: 0.313rem 1.5rem !important;
    font-size: 1.25rem;
    color: #870337;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a {
    font-size: 1.063rem;
    font-weight: 500;
    padding-top: 0.188rem;
    padding-bottom: 0.188rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show .navigation-teaser::before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: -1;
    width: 230px;
    height: 197px;
    background: #f1f1f1 url("../../Images/hatched.jpg") repeat;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show .navigation-teaser picture {
    display: block;
    margin-top: 15px;
    padding-left: 15px;
  }
  .nav-item .fa-chevron-down {
    position: relative;
    top: -1px;
    margin-left: 0.5rem;
  }
}

@keyframes dropdown-fade {
  from {
    opacity: 0;
    transform: translate(-50%, 1rem);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@media (min-width: 1316px) {
  .navbar-brand {
    margin-right: 2rem;
  }
}

/*IE 11 Browserhack to fix Submenu background issue*/
@media all and (-ms-high-contrast: none) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    min-width: 600px;
  }
}

.btn {
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 1px dotted;
  outline-offset: 2px;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  pointer-events: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-default {
  position: relative;
  color: #870337;
  font-size: 0.938rem;
  text-transform: uppercase;
  padding: 0.625rem 0.5rem 0.5rem 0.5rem;
}

.btn-default:focus, .btn-default.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(135, 3, 55, 0.2);
}

.btn-default:focus::before, .btn-default:focus::after, .btn-default.focus::before, .btn-default.focus::after {
  width: 100%;
  height: 100%;
  border-color: #870337;
}

.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active {
  background: #e2e2e2;
}

.btn-default::before, .btn-default::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  background: transparent;
}

.btn-default::before {
  bottom: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid #870337;
  border-right: 1px solid #870337;
  transition-property: height;
  transition-delay: 0.4s;
  transition-duration: 0.2s;
}

.btn-default::after {
  top: 0;
  right: 0;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  transition-property: height, width, border-color;
  transition-delay: 0s, 0.2s, 0.4s;
  transition-duration: 0.2s, 0.2s, 0s;
}

.btn-default:hover {
  color: #870337;
}

.btn-default:hover::before {
  height: 100%;
  transition-delay: 0s;
}

.btn-default:hover::after {
  width: 100%;
  height: 100%;
  border-top-color: #870337;
  border-left-color: #870337;
  transition-property: border-color, width, height;
  transition-delay: 0.2s, 0.2s, 0.4s;
  transition-duration: 0s, 0.2s, 0.2s;
}

.btn-secondary {
  color: #fff;
  background-color: #870337;
  border-color: #870337;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.625rem 0.5rem 0.5rem 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, transform 0.5s;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #870337;
  border-color: #870337;
  transform: scale(1.05);
}

.btn-secondary:focus, .btn-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(135, 3, 55, 0.2);
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  color: #fff;
  background: #69022b;
}

.btn-white {
  color: #2b2b2b;
  background-color: rgba(43, 43, 43, 0);
  border-color: #2b2b2b;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.75rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.btn-white:hover {
  color: #2b2b2b;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.btn-white:focus, .btn-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2);
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active {
  background: rgba(43, 43, 43, 0.5);
}

.btn-red {
  color: #fff;
  background-color: #870337;
  border-color: #870337;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.75rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.btn-red:hover {
  color: #2b2b2b;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.btn-red:focus, .btn-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(135, 3, 55, 0.2);
}

.btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active {
  color: #fff;
  background: #69022b;
}

@media (min-width: 992px) {
  .btn-red {
    font-size: 1.25rem;
    padding: 1rem 1.5rem;
  }
}

.btn-link {
  color: #870337;
  text-decoration: none;
  border: 0;
}

.btn-link:hover {
  color: #3c0119;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1420px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1420px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1420px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1420px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #2b2b2b !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.frame {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 2.813rem;
  margin-bottom: 2.813rem;
}

@media (min-width: 992px) {
  .frame {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

@media (min-width: 1420px) {
  .frame {
    margin-top: 4.688rem;
    margin-bottom: 4.688rem;
  }
}

.frame > *:last-child {
  margin-bottom: 0;
}

.frame-inner > *:last-child {
  margin-bottom: 0;
}

.frame-ruler-before {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.frame-ruler-before .frame-inner {
  margin-top: 1rem;
}

.frame-ruler-after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.frame-ruler-after .frame-inner {
  margin-bottom: 1rem;
}

.frame-background-dark,
.bg-dark {
  color: #fff;
  background-color: #2b2b2b;
}

.frame-background-dark a:not(.btn),
.bg-dark a:not(.btn) {
  color: #fff;
}

.frame-background-dark a:not(.btn):focus, .frame-background-dark a:not(.btn):hover,
.bg-dark a:not(.btn):focus,
.bg-dark a:not(.btn):hover {
  color: #d9d9d9;
}

.frame-background-dark {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2.813rem;
  padding-bottom: 2.813rem;
}

@media (min-width: 992px) {
  .frame-background-dark {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 1420px) {
  .frame-background-dark {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4.688rem;
    padding-bottom: 4.688rem;
  }
}

.frame-background-hatched,
.bg-hatched {
  color: #2b2b2b;
  background-color: #f1f1f1;
}

.frame-background-hatched a:not(.btn),
.bg-hatched a:not(.btn) {
  color: #870337;
}

.frame-background-hatched a:not(.btn):focus, .frame-background-hatched a:not(.btn):hover,
.bg-hatched a:not(.btn):focus,
.bg-hatched a:not(.btn):hover {
  color: #3c0119;
}

.frame-background-hatched {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2.813rem;
  padding-bottom: 2.813rem;
}

@media (min-width: 992px) {
  .frame-background-hatched {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 1420px) {
  .frame-background-hatched {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4.688rem;
    padding-bottom: 4.688rem;
  }
}

.frame-background-hatched {
  background: #f1f1f1 url("../../Images/hatched.jpg") repeat;
}

.frame-space-before-large.frame-background-none {
  margin-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-none {
    margin-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-none {
    margin-top: 11.25rem;
  }
}

.frame-space-before-large.frame-background-dark {
  padding-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-dark {
    padding-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-dark {
    padding-top: 11.25rem;
  }
}

.frame-space-before-large.frame-background-hatched {
  padding-top: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-before-large.frame-background-hatched {
    padding-top: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-large.frame-background-hatched {
    padding-top: 11.25rem;
  }
}

.frame-space-after-large.frame-background-none {
  margin-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-none {
    margin-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-none {
    margin-bottom: 11.25rem;
  }
}

.frame-space-after-large.frame-background-dark {
  padding-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-dark {
    padding-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-dark {
    padding-bottom: 11.25rem;
  }
}

.frame-space-after-large.frame-background-hatched {
  padding-bottom: 6.75rem;
}

@media (min-width: 992px) {
  .frame-space-after-large.frame-background-hatched {
    padding-bottom: 9.75rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-large.frame-background-hatched {
    padding-bottom: 11.25rem;
  }
}

.frame-space-before-small.frame-background-none {
  margin-top: 1.5rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-none {
    margin-top: 1.875rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-none {
    margin-top: 1.875rem;
  }
}

.frame-space-before-small.frame-background-dark {
  padding-top: 1.5rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-dark {
    padding-top: 1.875rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-dark {
    padding-top: 1.875rem;
  }
}

.frame-space-before-small.frame-background-hatched {
  padding-top: 1.5rem;
}

@media (min-width: 992px) {
  .frame-space-before-small.frame-background-hatched {
    padding-top: 1.875rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-before-small.frame-background-hatched {
    padding-top: 1.875rem;
  }
}

.frame-space-after-small.frame-background-none {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-none {
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-none {
    margin-bottom: 1.875rem;
  }
}

.frame-space-after-small.frame-background-dark {
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-dark {
    padding-bottom: 1.875rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-dark {
    padding-bottom: 1.875rem;
  }
}

.frame-space-after-small.frame-background-hatched {
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .frame-space-after-small.frame-background-hatched {
    padding-bottom: 1.875rem;
  }
}

@media (min-width: 1420px) {
  .frame-space-after-small.frame-background-hatched {
    padding-bottom: 1.875rem;
  }
}

.frame-space-before-none {
  margin-top: 0;
  padding-top: 0;
}

.frame-space-after-none {
  margin-bottom: 0;
  padding-bottom: 0;
}

.frame-grid-columns .frame {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 991.98px) {
  .frame-grid-columns.xs-space > .row .col-12 {
    margin-bottom: 1.5rem;
  }
  .frame-grid-columns.xs-space > .row .col-12:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .frame-header h1.text-center, .frame-header h2.text-center {
    margin-left: auto;
    margin-right: auto;
  }
  .textmedia-right .frame-header h1, .textmedia-right .frame-header h2,
  .textmedia-left .frame-header h1,
  .textmedia-left .frame-header h2,
  .textpic-right .frame-header h1,
  .textpic-right .frame-header h2,
  .textpic-left .frame-header h1,
  .textpic-left .frame-header h2,
  .frame-grid-columns .frame-header h1,
  .frame-grid-columns .frame-header h2 {
    width: 100%;
    max-width: none;
  }
}

@media (min-width: 992px) {
  .frame-type-text .frame-inner > p,
  .frame-type-text .frame-inner > ol,
  .frame-type-text .frame-inner > ul,
  .frame-type-text .frame-inner .news-detail-lead > p,
  .frame-type-text .frame-inner .news-detail-lead > ol,
  .frame-type-text .frame-inner .news-detail-lead > ul,
  .frame-type-text .frame-inner .news-detail-text > p,
  .frame-type-text .frame-inner .news-detail-text > ol,
  .frame-type-text .frame-inner .news-detail-text > ul,
  .frame-type-textpic .textpic-above .textpic-text > p,
  .frame-type-textpic .textpic-above .textpic-text > ol,
  .frame-type-textpic .textpic-above .textpic-text > ul,
  .frame-type-textpic .textpic-below .textpic-text > p,
  .frame-type-textpic .textpic-below .textpic-text > ol,
  .frame-type-textpic .textpic-below .textpic-text > ul,
  .frame-type-textmedia .textmedia-above .textmedia-text > p,
  .frame-type-textmedia .textmedia-above .textmedia-text > ol,
  .frame-type-textmedia .textmedia-above .textmedia-text > ul,
  .frame-type-textmedia .textmedia-below .textmedia-text > p,
  .frame-type-textmedia .textmedia-below .textmedia-text > ol,
  .frame-type-textmedia .textmedia-below .textmedia-text > ul {
    width: 83.33333%;
    max-width: 60rem;
  }
  .frame-type-text .frame-inner > p.text-center,
  .frame-type-text .frame-inner > ol.text-center,
  .frame-type-text .frame-inner > ul.text-center,
  .frame-type-text .frame-inner .news-detail-lead > p.text-center,
  .frame-type-text .frame-inner .news-detail-lead > ol.text-center,
  .frame-type-text .frame-inner .news-detail-lead > ul.text-center,
  .frame-type-text .frame-inner .news-detail-text > p.text-center,
  .frame-type-text .frame-inner .news-detail-text > ol.text-center,
  .frame-type-text .frame-inner .news-detail-text > ul.text-center,
  .frame-type-textpic .textpic-above .textpic-text > p.text-center,
  .frame-type-textpic .textpic-above .textpic-text > ol.text-center,
  .frame-type-textpic .textpic-above .textpic-text > ul.text-center,
  .frame-type-textpic .textpic-below .textpic-text > p.text-center,
  .frame-type-textpic .textpic-below .textpic-text > ol.text-center,
  .frame-type-textpic .textpic-below .textpic-text > ul.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > p.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > ol.text-center,
  .frame-type-textmedia .textmedia-above .textmedia-text > ul.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > p.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > ol.text-center,
  .frame-type-textmedia .textmedia-below .textmedia-text > ul.text-center {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1420px) {
  .frame-type-text .frame-inner > p,
  .frame-type-text .frame-inner > ol,
  .frame-type-text .frame-inner > ul,
  .frame-type-text .frame-inner .news-detail-lead > p,
  .frame-type-text .frame-inner .news-detail-lead > ol,
  .frame-type-text .frame-inner .news-detail-lead > ul,
  .frame-type-text .frame-inner .news-detail-text > p,
  .frame-type-text .frame-inner .news-detail-text > ol,
  .frame-type-text .frame-inner .news-detail-text > ul,
  .frame-type-textpic .textpic-above .textpic-text > p,
  .frame-type-textpic .textpic-above .textpic-text > ol,
  .frame-type-textpic .textpic-above .textpic-text > ul,
  .frame-type-textpic .textpic-below .textpic-text > p,
  .frame-type-textpic .textpic-below .textpic-text > ol,
  .frame-type-textpic .textpic-below .textpic-text > ul,
  .frame-type-textmedia .textmedia-above .textmedia-text > p,
  .frame-type-textmedia .textmedia-above .textmedia-text > ol,
  .frame-type-textmedia .textmedia-above .textmedia-text > ul,
  .frame-type-textmedia .textmedia-below .textmedia-text > p,
  .frame-type-textmedia .textmedia-below .textmedia-text > ol,
  .frame-type-textmedia .textmedia-below .textmedia-text > ul {
    width: 66.66667%;
  }
}

@media (min-width: 992px) {
  .frame-grid-columns .frame-type-text .frame-inner > p,
  .frame-grid-columns .frame-type-text .frame-inner > ol,
  .frame-grid-columns .frame-type-text .frame-inner > ul {
    width: auto;
  }
}

@media (min-width: 1420px) {
  .frame-grid-columns .frame-type-text .frame-inner > p,
  .frame-grid-columns .frame-type-text .frame-inner > ol,
  .frame-grid-columns .frame-type-text .frame-inner > ul {
    width: auto;
  }
}

.frame-type-wvier_cardgroup {
  overflow: hidden;
}

.textmedia,
.textpic {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.textmedia a.btn-default,
.textpic a.btn-default {
  margin-top: 1rem;
}

.textmedia a picture,
.textpic a picture {
  overflow: hidden;
}

.textmedia a picture img,
.textpic a picture img {
  transition: all 1s;
}

.textmedia a:hover picture img,
.textpic a:hover picture img {
  transform: scale(1.05);
}

.textmedia-item,
.textpic-item {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.textmedia-text > *:last-child,
.textpic-text > *:last-child {
  margin-bottom: 0;
}

.textmedia-below .textmedia-text,
.textmedia-below .textpic-text,
.textpic-below .textmedia-text,
.textpic-below .textpic-text,
.textmedia-right .textmedia-text,
.textmedia-right .textpic-text,
.textpic-right .textmedia-text,
.textpic-right .textpic-text {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .textmedia-right .textmedia-item,
  .textmedia-right .textpic-item,
  .textmedia-left .textmedia-item,
  .textmedia-left .textpic-item,
  .textpic-right .textmedia-item,
  .textpic-right .textpic-item,
  .textpic-left .textmedia-item,
  .textpic-left .textpic-item {
    margin-bottom: 0;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .textpic-left .textpic-text {
    padding-left: 35px;
  }
  .textpic-right .textpic-text {
    padding-right: 35px;
  }
}

@media (max-width: 1420px) {
  .frame-type-image .container .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.gallery-item {
  padding-left: 10px;
  padding-right: 10px;
}

.gallery-item img,
.gallery-item picture {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.gallery-item figure {
  margin-bottom: 20px;
}

.gallery-item-size-1 {
  width: 100%;
}

.gallery-item-size-2 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-2 {
    width: calc(100% / 2);
  }
}

.gallery-item-size-3 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-3 {
    width: calc(99.9% / 3);
  }
}

.gallery-item-size-4 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-4 {
    width: calc(100% / 4);
  }
}

.frame-type-image {
  padding-left: 0;
  padding-right: 0;
}

.frame-type-image > .container {
  width: 100%;
  max-width: initial;
  padding-left: 0;
  padding-right: 0;
}

.frame-type-image .gallery-row {
  display: flex;
  flex-direction: row;
}

ul.splide__list li::before {
  content: '' !important;
}

.splide a.mfp-image {
  display: block;
  overflow: hidden;
}

.splide a.mfp-image img {
  transition: all 1s;
}

.splide a.mfp-image:hover img {
  transform: scale(1.05);
}

@media (min-width: 1216px) {
  .splide__arrows {
    padding-left: 20px !important;
  }
}

@media (min-width: 1420px) {
  .splide__arrows {
    padding-left: 0 !important;
  }
}

.splide__arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 30px;
  border: 0;
  padding: 0;
  background: transparent;
  color: #870337;
  transition: color 0.25s ease-in-out;
}

.splide__arrow .desktop-svg {
  display: inline-block;
}

.splide__arrow .desktop-svg svg {
  width: 70px;
  stroke: #555555;
}

.splide__arrow .mobile-svg {
  display: none;
}

.splide__arrow .mobile-svg svg {
  fill: #555555;
}

@media (min-width: 1216px) {
  .splide__arrow .desktop-svg {
    display: inline-block;
  }
  .splide__arrow .mobile-svg {
    display: none;
  }
}

.splide__arrow--prev .desktop-svg {
  transform: scaleX(-1);
}

.splide__arrow--prev .mobile-svg {
  transform: rotate(90deg);
}

.splide__arrow--next .mobile-svg {
  transform: rotate(-90deg);
}

.splide__arrow:disabled .mobile-svg svg {
  fill: rgba(85, 85, 85, 0.3);
}

.splide__arrow:disabled .desktop-svg svg {
  stroke: rgba(85, 85, 85, 0.3);
}

.splide__arrow:hover {
  cursor: pointer;
}

.splide__arrow:hover:not(:disabled) {
  color: #555555;
}

.splide__arrow:focus {
  outline: none;
}

.splide__arrow:focus:not(:disabled) {
  color: #555555;
}

.frame-w4-gray-box .frame-inner {
  background-color: #f8f8f8;
  box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.2);
  padding: 3.438rem 0.813rem;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 2045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 2044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 2046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
             * Remove all paddings around the image on small screen
             */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-arrow::before {
  border-right: 0;
  border-left: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  background: url("../../Icons/Theme/arrow2white.svg") no-repeat;
  background-size: cover;
  height: 40px;
  width: 40px;
}

.mfp-arrow::after {
  border-left: 0;
  border-right: 0;
  margin-left: 0;
  border-top-width: 0;
  border-bottom-width: 0;
}

.mfp-arrow-left::before {
  transform: rotate(90deg);
  margin-left: 5px;
}

.mfp-arrow-right::before {
  transform: rotate(-90deg);
  margin-left: 45px;
}

.w4-newsletterform {
  background-color: #555555;
}

.w4-newsletterform iframe {
  height: 365px;
  padding-left: 10px;
  padding-right: 10px;
}

.w4-newsletterform h4 {
  color: #fff;
  font-size: 2.063rem;
  padding-top: 60px;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.w4-newsletterform .newsletter-svg-container {
  position: relative;
}

.w4-newsletterform svg {
  position: absolute;
  height: 50px;
  width: 157px;
  left: 20px;
  top: -15px;
}

@media (min-width: 576px) {
  .w4-newsletterform iframe {
    height: 316px;
  }
  .w4-newsletterform svg {
    height: 100px;
    width: 314px;
    top: -45px;
  }
}

@media (min-width: 596px) {
  .w4-newsletterform iframe {
    height: 202px;
  }
}

@media (min-width: 768px) {
  .w4-newsletterform iframe {
    height: 196px;
  }
}

@media (min-width: 992px) {
  .w4-newsletterform iframe {
    height: 100px;
  }
}

@media (min-width: 1024px) {
  .w4-newsletterform svg {
    top: -20px;
  }
  .w4-newsletterform .newsletter-svg-container {
    max-width: 992px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .w4-newsletterform svg {
    left: -90px;
  }
}

html.main-navbar-open {
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    position: fixed;
    z-index: 1030;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.5rem 1rem;
    overflow-y: auto;
    background-color: #fff;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .nav-link .fa-chevron-down {
    transform: translate(0, -50%) rotate(-90deg);
  }
  .navbar-expand-lg .navbar-nav .nav-item-icon:nth-last-child(2) {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 2px solid #f6f6f6;
  }
  .navbar-expand-lg .navbar-nav .dropdown-close > .dropdown-item {
    font-weight: 500;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown {
    position: static;
  }
}

::selection {
  background: #7c7c7c;
  /* WebKit/Blink Browsers */
  color: #fff;
}

::-moz-selection {
  background: #7c7c7c;
  /* Gecko Browsers */
  color: #fff;
}

ul {
  list-style: none;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item):not(.dropdown-close):not(.dropdown-item):not(.dropdown-menu-item):not(.splide__slide):not(.dropdown-title):not(.dr-overview-item) {
  position: relative;
  line-height: 1.563rem;
  padding-left: 10px;
  padding-bottom: 8px;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item):not(.dropdown-close):not(.dropdown-item):not(.dropdown-menu-item):not(.splide__slide):not(.dropdown-title):not(.dr-overview-item):last-child {
  padding-bottom: 0;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item):not(.page-item)::before {
  content: "\2022";
  color: #555555;
  font-weight: 700;
  font-size: 2.5rem;
  display: inline-block;
  width: 1rem;
  left: 0;
  margin-left: -1rem;
  position: absolute;
}

/*** Sticky Buttons ***/
.scrolled .w4-sticky-buttons {
  right: 0;
  transition: right 0.3s;
}

.w4-sticky-buttons {
  display: none;
  position: fixed;
  z-index: 100;
  right: -80px;
  bottom: 60px;
  transition: right 0.3s;
}

@media (min-width: 768px) {
  .w4-sticky-buttons {
    display: block;
  }
}

.w4-sticky-buttons a {
  display: block;
  width: 60px;
  height: 60px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
}

.w4-sticky-buttons a.gallery-button {
  background: url("/typo3conf/ext/wvier_speciality/Resources/Public/Icons/Theme/image-gallery.svg") no-repeat #f8f8f8;
  background-position: center left 10px;
  background-size: 40px;
  margin-bottom: 15px;
  transition: background-size 0.2s;
}

.w4-sticky-buttons a.gallery-button:hover {
  background-size: 38px;
  transition: background-size 0.2s;
}

.w4-sticky-buttons a.social-button {
  background: url("/typo3conf/ext/wvier_speciality/Resources/Public/Icons/Theme/instagram-white.svg") no-repeat #870337;
  background-position: center left 10px;
  background-size: 40px;
  transition: background-size 0.2s;
}

.w4-sticky-buttons a.social-button:hover {
  background-size: 38px;
  transition: background-size 0.2s;
}

.skiplinks .sr-only:focus {
  display: inline-block;
  padding: 0.5rem;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  background-color: #fff;
  z-index: 1090;
}

.header-caption.animated {
  animation: fadeInHeader 1.4s linear 0.5s forwards;
}

/*Zeit für...*/
.zeit-fuer-svg {
  text-align: center;
  margin-bottom: 17px !important;
}

.zeit-fuer-svg svg {
  max-width: 230px;
}

@media (min-width: 992px) {
  .zeit-fuer-svg svg {
    max-width: 380px;
  }
}

/*New Header Text Line SHB*/
.page-1 .image-slider.header-splide::after {
  animation: fadeInHeader 1.4s linear 0.5s forwards;
}

.container .container {
  padding-left: 0;
  padding-right: 0;
}

.news-single .elements-wrapper .frame {
  margin-top: 2rem;
  margin-bottom: 0;
}

.news-single .news-detail-lead,
.news-single .news-detail-gallery {
  margin-top: 2rem;
}

.news-single .news-detail-text {
  margin-top: 1.5rem;
}

.footer {
  background-color: #f8f8f8;
}

@media (min-width: 992px) {
  .footer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.footer p {
  font-size: 1.063rem;
}

.footer a {
  font-weight: 300;
  color: #555555;
  font-size: 0.938rem;
}

.footer .footer-logo {
  text-align: center;
}

@media (min-width: 992px) {
  .footer .footer-logo {
    display: flex;
    align-items: flex-start;
    margin-top: -1rem !important;
  }
  .footer .footer-logo img {
    height: 116px;
  }
}

@media (min-width: 1420px) {
  .footer .footer-logo img {
    height: 145px;
  }
}

.footer .footer-address {
  text-align: center;
  color: #555555;
}

@media (min-width: 992px) {
  .footer .footer-address {
    text-align: left;
  }
}

.footer .footer-address .footer-adress-headling {
  text-transform: uppercase;
  font-family: "futura-pt", sans-serif;
  font-size: 1.563rem;
  font-weight: 500;
}

.footer-nav {
  text-align: center;
}

@media (min-width: 992px) {
  .footer-nav {
    text-align: left;
  }
}

.footer-nav a {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.footer .footer-facebook,
.footer .footer-instagram,
.footer .footer-youtube {
  text-indent: -9999px;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.footer .footer-facebook {
  background: url("../../Icons/Theme/facebook.svg") no-repeat;
}

.footer .footer-instagram {
  background: url("../../Icons/Theme/instagram.svg") no-repeat;
}

.footer .footer-youtube {
  background: url("../../Icons/Theme/youtube.svg") no-repeat;
}

.footer .footer-partner-grid {
  margin-top: -10px;
}

.footer .footer-partner-grid .col-4 {
  display: flex;
  margin-top: -20px;
  overflow: hidden;
}

.footer .footer-partner-grid img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 10px;
}

@media (min-width: 576px) {
  .footer .footer-partner-grid img {
    padding: 40px;
  }
}

@media (min-width: 768px) {
  .footer .footer-partner-grid img {
    padding: 60px;
  }
}

@media (min-width: 992px) {
  .footer .footer-partner-grid img {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .footer .footer-partner-grid .col-4 {
    margin-top: -30px;
  }
}

@media (min-width: 768px) {
  .frame-default.off-screen .frame-inner {
    transition: 0.8s ease;
    opacity: 0;
    transform: translate(0, 120px);
  }
  .frame-default.off-screen.animated .frame-inner {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fadeInHeader {
  0% {
    opacity: 0;
    transform: translate(1.5rem, 0.5rem);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.hc-widget {
  margin: 0 auto;
}
