@import "variables";
@import "fonts";

//IconFont
@import "iconfont";

//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";

$colors: map-remove($colors, "indigo", "purple", "pink", "red", "orange", "yellow", "green", "teal", "cyan", "gray", "gray-dark");
$theme-colors: map-remove($theme-colors, "primary", "secondary", "success", "info", "warning", "danger", "light", "dark");

//Bootstrap Optional
//@import "../../Contrib/Bootstrap/scss/type" --> custom;
@import "../../Contrib/Bootstrap/scss/images";
//@import "../../Contrib/Bootstrap/scss/code";
//@import "../../Contrib/Bootstrap/scss/grid";
@import "../../Contrib/Bootstrap/scss/tables";
//@import "../../Contrib/Bootstrap/scss/buttons"; --> custom
@import "../../Contrib/Bootstrap/scss/transitions";
@import "../../Contrib/Bootstrap/scss/dropdown";
//@import "../../Contrib/Bootstrap/scss/button-group";
//@import "../../Contrib/Bootstrap/scss/input-group";
//@import "../../Contrib/Bootstrap/scss/custom-forms";
@import "../../Contrib/Bootstrap/scss/nav";
//@import "../../Contrib/Bootstrap/scss/navbar";
//@import "../../Contrib/Bootstrap/scss/card";
@import "../../Contrib/Bootstrap/scss/breadcrumb";
//@import "../../Contrib/Bootstrap/scss/pagination";
//@import "../../Contrib/Bootstrap/scss/badge";
//@import "../../Contrib/Bootstrap/scss/jumbotron";
//@import "../../Contrib/Bootstrap/scss/alert";
//@import "../../Contrib/Bootstrap/scss/progress";
//@import "../../Contrib/Bootstrap/scss/media";
//@import "../../Contrib/Bootstrap/scss/list-group";
//@import "../../Contrib/Bootstrap/scss/close";
//@import "../../Contrib/Bootstrap/scss/toasts";
//@import "../../Contrib/Bootstrap/scss/modal";
//@import "../../Contrib/Bootstrap/scss/tooltip";
//@import "../../Contrib/Bootstrap/scss/popover";
//@import "../../Contrib/Bootstrap/scss/carousel";
//@import "../../Contrib/Bootstrap/scss/spinners";
//@import "../../Contrib/Bootstrap/scss/utilities";
@import "../../Contrib/Bootstrap/scss/print";

// Custom Styles
@import "navbar";
@import "bsbuttons";
@import "bsutilities";
@import "frame";
@import "textpic";
@import "gallery";
@import "graybox";
@import "magnific";
@import "newsletterform";

// Navbar
html.main-navbar-open {
    overflow: hidden;
}

@include media-breakpoint-down(md) {
    .navbar-expand-lg {
        .navbar-collapse {
            position: fixed;
            z-index: $zindex-fixed;
            top: 120px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0.5rem 1rem;
            overflow-y: auto;
            background-color: $white;
        }

        .navbar-nav {
            .nav-link {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .fa-chevron-down {
                    transform: translate(0, -50%) rotate(-90deg);
                }
            }

            .nav-item-icon:nth-last-child(2) {
                margin-top: 1.5rem;
                padding-top: 1.5rem;
                border-top: 2px solid $gray-100
            }

            .dropdown-close {
                > .dropdown-item {
                    font-weight: $font-weight-bold;
                }
            }

            .dropdown-menu {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .dropdown {
                position: static;
            }
        }
    }
}

// Text selection
::selection {
    background: $selection-bg; /* WebKit/Blink Browsers */
    color: $selection-color;
}

::-moz-selection {
    background: $selection-bg; /* Gecko Browsers */
    color: $selection-color;
}


ul {
    list-style: none;
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item):not(.dropdown-close):not(.dropdown-item):not(.dropdown-menu-item):not(.splide__slide):not(.dropdown-title):not(.dr-overview-item) {
    position: relative;
    line-height: 1.563rem;
    padding-left: 10px;
    padding-bottom: 8px;

    &:last-child {
        padding-bottom: 0;
    }
}

ul li:not(.nav-item):not(.list-item):not(.langnav-item):not(.page-item)::before {
    content: "\2022";
    color: $bullet-color;
    font-weight: 700;
    font-size: 2.5rem;
    display: inline-block;
    width: 1rem;
    left: 0;
    margin-left: -1rem;
    position: absolute;
}

/*** Sticky Buttons ***/
.scrolled {
    .w4-sticky-buttons {
        right: 0;
        transition: right 0.3s;
    }
}

.w4-sticky-buttons {
    display: none;
    position: fixed;
    z-index: 100;
    right: -80px;
    bottom: 60px;
    transition: right 0.3s;

    @include media-breakpoint-up(md) {
        display: block;
    }

    a {
        display: block;
        width: 60px;
        height: 60px;
        box-shadow: $sticky-buttons-shadow;

        &.gallery-button {
            background: url('/typo3conf/ext/wvier_speciality/Resources/Public/Icons/Theme/image-gallery.svg') no-repeat $gray-f8;
            background-position: center left 10px;
            background-size: 40px;
            margin-bottom: 15px;
            transition: background-size 0.2s;

            &:hover {
                background-size: 38px;
                transition: background-size 0.2s;
            }
        }

        &.social-button {
            background: url('/typo3conf/ext/wvier_speciality/Resources/Public/Icons/Theme/instagram-white.svg') no-repeat $primary;
            background-position: center left 10px;
            background-size: 40px;
            transition: background-size 0.2s;

            &:hover {
                background-size: 38px;
                transition: background-size 0.2s;
            }
        }
    }
}

// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-skiplinks;
        }
    }
}

.header-caption {
    &.animated {
        animation: fadeInHeader 1.4s linear 0.5s forwards;
    }
}

/*Zeit für...*/
.zeit-fuer-svg {
    text-align: center;
    margin-bottom: 17px !important;

    svg {
        max-width: 230px;

        @include media-breakpoint-up(lg) {
            max-width: 380px;
        }
    }
}

/*New Header Text Line SHB*/
.page-1 .image-slider.header-splide::after {
    animation: fadeInHeader 1.4s linear 0.5s forwards;
}

// Layout
.container {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

//News List View
.news-single {
    .elements-wrapper {
        .frame {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    .news-detail-lead,
    .news-detail-gallery {
        margin-top: 2rem;
    }

    .news-detail-text {
        margin-top: 1.5rem;
    }
}

// Footer
.footer {
    background-color: $footer-bg-color;

    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    p {
        font-size: 1.063rem;
    }

    a {
        font-weight: 300;
        color: $footer-link-color;
        font-size: 0.938rem;
    }

    .footer-logo {
        text-align: center;

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: flex-start;
            margin-top: -1rem !important;

            img {
                height: 116px;
            }
        }

        @include media-breakpoint-up(xl) {
            img {
                height: 145px;
            }
        }

    }

    .footer-address {
        text-align: center;
        color: $footer-text-color;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        .footer-adress-headling {
            text-transform: uppercase;
            font-family: $font-family-futurapt;
            font-size: 1.563rem;
            font-weight: 500;
        }
    }

    &-nav {
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        a {
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.875rem;
            text-transform: uppercase;
        }
    }

    .footer-facebook,
    .footer-instagram,
    .footer-youtube {
        text-indent: -9999px;
        display: inline-block;
        height: 30px;
        width: 30px;
    }

    .footer-facebook {
        background:url("../../Icons/Theme/facebook.svg") no-repeat;
    }

    .footer-instagram {
        background:url("../../Icons/Theme/instagram.svg") no-repeat;
    }
    .footer-youtube {
        background:url("../../Icons/Theme/youtube.svg") no-repeat;
    }

    .footer-partner-grid {
        margin-top: -10px;

        .col-4 {
            display: flex;
            margin-top: -20px;
            overflow: hidden;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            padding: 10px;

            @include media-breakpoint-up(sm) {
                padding: 40px;
            }

            @include media-breakpoint-up(md) {
                padding: 60px;
            }

            @include media-breakpoint-up(lg) {
                padding: 20px;
            }


        }
    }

    @include media-breakpoint-up(md) {
        .footer-partner-grid {
            .col-4 {
                margin-top: -30px;
            }
        }
    }

}

@include media-breakpoint-up(md) {
    .frame-default.off-screen {
        .frame-inner {
            transition: 0.8s ease;
            opacity: 0;
            transform: translate(0, 120px);
        }

        &.animated {
            .frame-inner {
                transform: translate(0, 0);
                opacity: 1;
            }
        }
    }
}

@keyframes fadeInHeader {
    0% {
        opacity: 0;
        transform: translate(1.5rem, 0.5rem);
    }
    80% {
        transform: translate(0, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.hc-widget {
    margin: 0 auto;
}
