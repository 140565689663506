$fonts-url-absolute: '/typo3conf/ext/wvier_speciality/Resources/Public/Fonts/';

$fonts-raleway-url-absolute: '#{$fonts-url-absolute}Raleway/';
$fonts-dmserifdisplay-url-absolute: '#{$fonts-url-absolute}Dmserifdisplay/';

/* raleway-300 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300.woff') format('woff'), /* Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500.woff') format('woff'), /* Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-300italic - latin */
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-300italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500italic - latin */
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500italic.woff') format('woff'), /* Modern Browsers */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fonts-raleway-url-absolute}raleway-v18-latin-500italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* dm-serif-display-regular - latin */
@font-face {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-regular.svg#DMSerifDisplay') format('svg'); /* Legacy iOS */
}
/* dm-serif-display-italic - latin */
@font-face {
    font-family: 'DM Serif Display';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fonts-dmserifdisplay-url-absolute}dm-serif-display-v4-latin-italic.svg#DMSerifDisplay') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight:400;
}

@font-face {
    font-family:"futura-pt";
    src:url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display: swap;
    font-style:normal;
    font-weight:500;
}