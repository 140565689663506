// Textpic and Textmedia

.textmedia,
.textpic {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1 * ($grid-gutter-width / 2);
    margin-right: -1 * ($grid-gutter-width / 2);

    a {
        &.btn-default {
            margin-top: 1rem;
        }

        picture {
            overflow: hidden;

            img {
                transition: all 1s;
            }
        }

        &:hover {
            picture {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.textmedia-item,
.textpic-item {
    width: 100%;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
}

.textmedia-text,
.textpic-text {
    > *:last-child {
        margin-bottom: 0;
    }
}

.textmedia-below,
.textpic-below,
.textmedia-right ,
.textpic-right{
    .textmedia-text,
    .textpic-text {
        margin-bottom: $spacer * 3;
    }
}

@include media-breakpoint-up(md) {
    .textmedia-right,
    .textmedia-left,
    .textpic-right,
    .textpic-left {
        .textmedia-item,
        .textpic-item {
            margin-bottom: 0;
            width: 50%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .textpic-left {
        .textpic-text {
            padding-left: 35px;
        }
    }

    .textpic-right {
        .textpic-text {
            padding-right: 35px;
        }
    }

}
