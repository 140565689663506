// Gallery

.frame-type-image .container .container {
    @media (max-width: 1420px) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }
}

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
}

.gallery-item {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    img,
    picture {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    figure {
        margin-bottom: $grid-gutter-width;
    }
}


// Gallery Item Sizes

.gallery-item-size-1 {
    width: 100%;
}
.gallery-item-size-2 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(100% / 2);
    }
}
.gallery-item-size-3 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(99.9% / 3);
    }
}

.gallery-item-size-4 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(100% / 4);
    }
}

.frame-type-image {
    padding-left: 0;
    padding-right: 0;

    > .container {
        width: 100%;
        max-width: initial;
        padding-left: 0;
        padding-right: 0;
    }

    .gallery-row {
        display: flex;
        flex-direction: row;
    }
}

ul.splide__list li {
    &::before {
        content: '' !important;
    }
}

.splide {
    a.mfp-image {
        display: block;
        overflow: hidden;

        img {
            transition: all 1s;
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__arrows {
        @media (min-width: 1216px) {
            padding-left: $grid-gutter-width !important;
        }

        @media (min-width: 1420px) {
            padding-left: 0 !important;
        }
    }

    &__arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 30px;
        border: 0;
        padding: 0;
        background: transparent;
        color: $primary;
        transition: color 0.25s ease-in-out;

        .desktop-svg {
            display: inline-block;

            svg {
                width: 70px;
                stroke: $gray-555;
            }
        }

        .mobile-svg {
            display: none;

            svg {
                fill: $gray-555;
            }
        }

        @media (min-width: 1216px) {
            .desktop-svg {
                display: inline-block;
            }
            .mobile-svg {
                display: none;
            }
        }

        &--prev {
            .desktop-svg {
                transform:scaleX(-1);
            }

            .mobile-svg {
                transform:rotate(90deg);
            }
        }

        &--next {
            .mobile-svg {
                transform:rotate(-90deg);
            }
        }

        &:disabled {
            .mobile-svg {
                svg {
                    fill: rgba($gray-555, 0.3);
                }
            }
            .desktop-svg {
                svg {
                    stroke: rgba($gray-555, 0.3);
                }
            }
        }

        &:hover {
            cursor: pointer;
            &:not(:disabled) {
                color: $gray-555;
            }
        }

        &:focus {
            outline: none;

            &:not(:disabled) {
                color: $gray-555;
            }
        }
    }
}
