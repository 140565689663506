$mfp-z-index-base: 2040;
@import "../../Contrib/MagnificPopup/main.scss";

.mfp-arrow {

    &::before {
        border-right: 0;
        border-left: 0;
        border-bottom-width: 0;
        border-top-width: 0;
        background: url('../../Icons/Theme/arrow2white.svg') no-repeat;
        background-size: cover;
        height: 40px;
        width: 40px;
    }
    &::after {
        border-left: 0;
        border-right: 0;
        margin-left: 0;
        border-top-width: 0;
        border-bottom-width: 0;
    }
}

.mfp-arrow-left {
    &::before {
        transform: rotate(90deg);
        margin-left: 5px;
    }
}
.mfp-arrow-right {
    &::before {
        transform: rotate(-90deg);
        margin-left: 45px;
    }
}